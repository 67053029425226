<template>
	<v-row>
		<v-snackbar
			centered
			:color="snackbar.cor"
			v-model="snackbar.status"
			:timeout="snackbar.timeout"
			dark
		>
			<span class="font-weight-bold text-uppercase">{{snackbar.text}}</span>
			<template v-slot:action="{ attrs }">
				<v-btn class="font-weight-bold" text v-bind="attrs" @click="snackbar.status = false">Fechar</v-btn>
			</template>
		</v-snackbar>
		<v-dialog v-model="alerta" persistent max-width="575">
			<v-card>
				<v-card-title class="text-h5">
					<span class="mx-auto">Exclusão de produtos</span>
				</v-card-title>
				<v-divider />
				<div class="text-center pt-5">
					<v-icon class="text-h1" color="error">mdi-help-circle-outline</v-icon>
				</div>
				<v-card-text
					class="text-center text-subtitle-1"
				>Confirmar a exclusão de {{produtos.length}} produto(s) do pedido?</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn text color="primary" dark class="px-5" elevation="0" @click="alerta = false">Cancelar</v-btn>
					<v-btn color="primary" class="px-5" elevation="0" @click="limparPedido()">Confimar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12">
			<v-card>
				<v-card-title>
					<span class="d-none d-md-block">Venda de Concreto</span>
					<v-spacer />
					<v-btn
						v-if="visualizacao == 'lista'"
						elevation="0"
						icon
						light
						class="primary--text d-none d-md-block"
						@click="visualizacao = 'card'"
					>
						<v-icon>mdi-card-bulleted</v-icon>
					</v-btn>
					<v-btn
						v-if="visualizacao == 'card'"
						elevation="0"
						icon
						light
						class="primary--text d-none d-md-block"
						@click="visualizacao = 'lista'"
					>
						<v-icon>mdi-format-list-text</v-icon>
					</v-btn>
					<v-btn
						:disabled="produtos.length == 0"
						elevation="0"
						class="mx-3"
						color="primary"
						@click="alerta = true"
					>
						<span class="d-none d-md-block">Limpar pedido</span>
						<v-icon class="ml-0 ml-md-3">mdi-close</v-icon>
					</v-btn>
					<v-menu
						v-model="menu"
						:open-on-click="true"
						:close-on-content-click="false"
						right
						offset-y
						tile
					>
						<template v-slot:activator="{ on }">
							<v-badge color="red" overlap :content="produtos.length" :value="produtos.length">
								<v-btn v-on="on" :disabled="produtos.length == 0" elevation="0" color="primary">
									<span class="d-none d-md-block">Ver pedido</span>
									<v-icon class="ml-0 ml-md-3">mdi-cart-outline</v-icon>
								</v-btn>
							</v-badge>
						</template>
						<v-card elevation="0" scrollable outlined>
							<v-card-title>
								Pedido
								<v-spacer />
								<v-btn
									:disabled="produtos.length == 0"
									fab
									elevation="0"
									color="primary"
									x-small
									to="/concreto/faturar"
								>
									<v-icon>mdi-cart-outline</v-icon>
								</v-btn>
							</v-card-title>
							<v-divider />
							<v-card-text>
								<v-scroll-x-transition
									leave-absolute
									group
									tag="ul"
									v-if="produtos.length > 0"
									class="px-0"
								>
									<v-list-item v-for="(item, i) in produtos" :key="i">
										<v-list-item-avatar>
											<v-img v-if="item.linkimagem" :src="item.linkimagem" />
											<v-img v-else src="../../assets/icons/sem_imagem.png" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title>{{item.idsubproduto}} - {{item.descricao}}</v-list-item-title>
											<v-list-item-subtitle>Quantidade: {{item.quantidade}} {{item.embalagem}}</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-content>
											<div class="text-right text-h6" style="height: 25px">
                          <span class="d-none d-md-inline" style="position: absolute;right: 55px">{{(item.venda) * item.quantidade | formataDinheiro}}</span>
                          <v-btn
                            v-if="item.idsubproduto != 85768"
                            class="ml-2 mb-1"
                            fab
                            elevation="0"
                            x-small
                            color="red"
                            dark
                            @click="removerProdutoPedido(i)"
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
											</div>
										</v-list-item-content>
									</v-list-item>
								</v-scroll-x-transition>
								<v-list v-else>
									<v-list-item>
										<v-list-item-content>
											<div class="text-center mb-3">Nenhum produto adicionado ao carrinho</div>
										</v-list-item-content>
									</v-list-item>
								</v-list>
								<v-divider class="mt-5" />
								<v-list-item class="mt-3">
									<v-list-item-content>
										<div
											v-if="produtos.length > 0"
											class="text-right text-h5"
										>Total bruto: {{calculaTotal() | formataDinheiro}}</div>
										<div v-else class="text-right text-h5">Total bruto: R$ 0,00</div>
									</v-list-item-content>
								</v-list-item>
							</v-card-text>
							<v-divider />
							<v-card-actions>
								<v-btn
									v-if="produtos.length > 0"
									elevation="0"
									color="primary"
									block
									tile
									to="/concreto/faturar"
								>
									Ver pedido
									<v-icon class="ml-3">mdi-cart-outline</v-icon>
								</v-btn>
								<v-btn v-else elevation="0" color="primary" block tile @click="menu = !menu">
									Fechar
									<v-icon class="ml-3">mdi-close</v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-menu>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" class="d-flex mb-n7">
							<v-text-field label="Buscar produtos" clearable outlined dense v-model="busca.busca" />
						</v-col>
						<template v-if="buscado">
							<v-col class="text--md-h6 text-body-1 font-weight-bold">Resultados para "{{buscado}}"</v-col>
							<v-col cols="auto" class="text-right text-body-1">{{dados.total}} produtos</v-col>
						</template>
						<template v-else>
              <v-col></v-col>
							<v-col cols="auto" class="text-right text-body-1">{{dados.total}} produtos</v-col>
						</template>
					</v-row>
					<v-row v-if="carregandoSkeleton">
						<v-col v-for="i in 12" :key="i" cols="12" md="3">
							<v-skeleton-loader type="card" />
						</v-col>
					</v-row>
					<template v-else>
						<v-row v-if="dados.total > 0">
							<v-col
								v-for="(item, i) in dados.lista"
								:key="i"
								cols="12"
								:md="visualizacao == 'card' ? 3: 12"
								class="mb-n3"
							>
                <!-- tela tipo1-->
								<v-card
									style="border-left: 5px solid #999999 !important;"
									color="grey lighten-4"
									elevation="1"
									v-if="visualizacao == 'card'"
								>
									<v-img
										v-if="item.linkimagem || item.linkimagem == ''"
										width="100%"
										height="180"
										:src="item.linkimagem"
										:lazy-src="item.linkimagem"
									></v-img>
									<v-img
										v-else
										width="100%"
										height="180"
										src="../../assets/icons/sem_imagem.png"
										lazy-src="../../assets/icons/sem_imagem.png"
									></v-img>
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-card-title
												v-on="on"
												class="d-none d-md-block subtitle-1 font-weight-bold text-truncate pt-0 px-2"
											>{{item.idsubproduto}} - {{item.descricao}}</v-card-title>
											<v-card-title
												v-on="on"
												class="d-block d-md-none subtitle-2 pt-0 px-2"
											>{{item.idsubproduto}} - {{item.descricao}}</v-card-title>
										</template>
										<span>{{item.idsubproduto}} - {{item.descricao}}</span>
									</v-tooltip>
									<v-card-text class="px-2 pb-3">
										<v-text-field
											class="mb-n4"
											:suffix="item.embalagem"
											persistent-hint
											dense
											label="Quantidade"
											type="number"
											v-model.number="item.quantidade"
											v-mask="'#########'"
										/>
                    <v-row>
                      <v-col>
                        <v-btn elevation="0" small color="primary" class="px-2" @click="adicionarProdutoPedido(item)">
                          Adicionar
                          <v-divider vertical class="mx-2 white"/>
                          <v-icon size="16">mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer />
                      <v-col>
                        <span class="text-h5 text-truncate font-weight-bold float-end">{{item.venda | formataDinheiro}}</span>
                      </v-col>
                    </v-row>
									</v-card-text>
								</v-card>
                <!-- tela tipo2-->
								<v-card
									v-if="visualizacao == 'lista'"
									elevation="0"
									style="border: 2px solid #999999;"
									min-width="800px"
								>
									<v-row
										align="center"
										class="my-2 pt-1 pb-2"
										no-gutters
										style="border-bottom: 1px solid #999999"
									>
										<v-col cols="auto" class="mx-2">
											<v-avatar>
												<v-img
													v-if="item.linkimagem || item.linkimagem == ''"
													:src="item.linkimagem"
													:lazy-src="item.linkimagem"
												/>
												<v-img
													v-else
													src="../../assets/icons/sem_imagem.png"
													lazy-src="../../assets/icons/sem_imagem.png"
												/>
											</v-avatar>
										</v-col>
										<v-col class="text-truncate">
											<v-tooltip top>
												<template v-slot:activator="{ on }">
													<span v-on="on">{{item.idsubproduto}} - {{item.descricao}}</span>
												</template>
												<span>{{item.idsubproduto}} - {{item.descricao}}</span>
											</v-tooltip>
										</v-col>
										<v-col cols="auto" class="mx-2">
											<v-btn
												class="ml-1"
												fab
												elevation="0"
												x-small
												color="primary"
												@click="adicionarProdutoPedido(item)"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</v-col>
									</v-row>
									<v-row align="center" class="mb-n1 pt-1" no-gutters>
										<template v-if="item.promocao > 0">
											<v-col class="mx-5 text-center" cols="2">
												<v-badge icon="mdi-arrow-down" color="green" overlap>
													<span class="text-decoration-line-through">{{item.venda | formataDinheiro}}</span>
												</v-badge>
												<strong class="ml-4">{{item.promocao | formataDinheiro}}</strong>
											</v-col>
										</template>
										<v-col v-else class="mx-5 text-center" cols="2">
											<strong>{{item.venda | formataDinheiro}}</strong>
										</v-col>
										<v-col class="mb-n4 mr-2">
											<v-text-field
												outlined
												:suffix="item.embalagem"
												persistent-hint
												dense
												label="Quantidade"
												type="number"
												v-model.number="item.quantidade"
												v-mask="'#########'"
											/>
										</v-col>
									</v-row>
								</v-card>
							</v-col>
							<v-col class="text-center" cols="12">
								<Paginacao
									:total="dados.total"
									@atualizar="listar"
									:pgLimitInt="pgLimit"
									:carregando="carregando"
									:pg="pgSelecionada"
								/>
							</v-col>
						</v-row>
						<v-row v-else>
							<v-col>
								<v-card flat class="text-center">
									<v-card-text class="text-h6">Nenhum produto encontrato.</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</template>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

import Paginacao from "../Widgets/Paginacao.vue";

export default {
	name: "ConcretoProduto",
	components: { Paginacao },
	data: () => ({
		visualizacao: "card",
		snackbar: { status: false, timeout: 1500 },
		alerta: false,
		menu: false,
		carregando: false,
		carregandoSkeleton: true,
		dados: { lista: [], total: 0 },
		bomba: {
			idsubproduto: 85768,
			descricao: "SERVIÇOS DE BOMBA",
			embalagem: "UN",
			venda: 15,
			tipo: "B",
			quantidade: 1,
			desconto: 0,
		},
		busca: { busca: null, estoque: true },
		buscado: "",
		produtos: [],
		produto: {},
		pgLimit: 12,
		aguardarBusca: "",
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "timeLimit", "usuario"]),
	},
	methods: {
		// Função para listar produtos para venda de concreto
		listar(pg) {
			this.pgSelecionada = pg;
			this.buscado = this.busca.busca;

			clearTimeout(this.aguardarBusca);
			this.aguardarBusca = setTimeout(() => {
				this.carregando = true;
				return axios
					.post(`${this.backendUrl}concreto/produto/listar`, {
						limit: this.pgLimit,
						offset: this.pgLimit * pg,
						busca: this.busca.busca || null,
					})
					.then((res) => {
						this.carregandoSkeleton = false;
						let dados = res.data;
						this.dados.total = dados.total;
						dados.lista.forEach((v) => {
							v.quantidade = 1;
							v.desconto = 0;
						});
						this.dados.lista = dados.lista;
						this.carregando = false;
					})
					.catch(() => {
						this.carregandoSkeleton = false;
						this.dados = { lista: [], total: 0 };
						this.carregando = false;
					});
			}, this.timeLimit);
		},
		// Função para remover todos os produtos do pedido
		limparPedido() {
			this.produtos = [];
			this.validaBomba();
			localStorage.removeItem("pedidoConcreto");
			this.alerta = false;
			this.snackbar = {
				text: "Todos os produtos removidos do pedido",
				cor: "primary",
				status: true,
			};
		},
		// Função adiciona produto ao pedido
		adicionarProdutoPedido(produto) {
			if (produto.quantidade <= 0) {
				this.snackbar = {
					text: "A quantidade do produto tem que ser superior a 0.",
					cor: "error",
					status: true,
				};
				return;
			}

			const prod = { ...produto };
			this.produtos.push(prod);
			this.validaBomba();
			localStorage.setItem(
				"pedidoConcreto",
				JSON.stringify(this.produtos)
			);
			this.produtos.sort((a, b) => a.idsubproduto - b.idsubproduto);
			this.snackbar = {
				text: "Produto adicionado ao carrinho (pedido)",
				cor: "primary",
				status: true,
			};
		},
		// Função remove produto do pedido
		removerProdutoPedido(i) {
			this.produtos.splice(i, 1);
			this.validaBomba();
			localStorage.setItem(
				"pedidoConcreto",
				JSON.stringify(this.produtos)
			);
      this.snackbar = {
        text: "Produto removido do pedido",
        cor: "primary",
        status: true,
      };
		},
		// Função calcula valor total do pedido
		calculaTotal() {
			// TODO:  Validar se os valores se deve usar preço à vista ou preço à prazo
			return this.produtos.reduce(
				(total, v) => total + v.venda * v.quantidade,
				0
			);
		},
		// Valida bomba de serviço
		validaBomba() {
			const idBomba = 85768;
			this.produtos = this.produtos.filter(
				(produto) => produto.idsubproduto !== idBomba
			);
			const temBombeavel = this.produtos.some(
				(produto) => produto.tipo === "B"
			);
			if (temBombeavel) {
				const quantidadeBombeavel = this.produtos
					.filter((produto) => produto.tipo === "B")
					.reduce((total, produto) => total + produto.quantidade, 0);
				this.bomba.quantidade = Math.max(quantidadeBombeavel, 10);
				this.produtos.push(this.bomba);
			}
		},
		async init() {
			const json = localStorage.getItem("pedidoConcreto");
			const produtos = JSON.parse(json);
			if (produtos) {
				this.produtos = produtos;
			}
			await this.listar(0);
		},
	},
	watch: {
		"busca.busca": function () {
			this.listar(0);
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>